import React, { useState } from "react";
import Form from 'react-bootstrap/Form'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function DataModal({ isOpen, modalType, formData, handleFormChange, handleFormSubmit, handleFormCancel }) {
  console.log()
  return (
    <Modal isOpen={isOpen} >
      <Form onSubmit={handleFormSubmit}>
        <ModalHeader >{modalType}資料</ModalHeader>
        <ModalBody>

          <Form.Group className="mb-3" controlId="desc">
            <Form.Label>名稱</Form.Label>
            <Form.Control
              type="text"
              name="desc"
              placeholder="請輸入名稱"
              required="required"
              value={formData.desc}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="price">
            <Form.Label>價格</Form.Label>
            <Form.Control
              type="text"
              name="price"
              placeholder="請輸入價格"
              required="required"
              value={formData.price}
              onChange={handleFormChange}
            />
          </Form.Group>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit"
          // onClick={submit}
          >
            {modalType}
          </Button>{" "}
          <Button color="secondary"
            onClick={handleFormCancel}
          >
            取消
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
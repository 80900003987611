import React, { useState } from 'react'
import { Box, List, ListItem, ListItemText, Button } from '@mui/material';
import ProductAdd from './ProductAdd';
import DataModal from './DataModal';
import { nanoid } from 'nanoid';
import Container from "react-modal-promise";
import { useTheme } from '@mui/material/styles';
import AppMenu from './AppMenu';

export default function ProductList() {
  const theme = useTheme();

  const activeStyle = { backgroundColor: theme.palette.secondary.main, color: "black" };

  const [products, setProducts] = useState([

    { desc: "iPad", price: 20000 },

    { desc: "iPhone X", price: 30000 }

  ]);

  const [selectedIndex, setSelectedIndex] = useState();
  const [formModalType, setformModalType] = useState(null);

  const [addFormData, setAddFormData] = useState({
    desc: "",
    price: ""
  });

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    console.log(index)
  };
  const insert = function (newProduct) {

    setProducts(oldProducts => [...oldProducts, newProduct]);

  }
  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    const newProduct = {
      id: nanoid(),
      desc: addFormData.desc,
      price: addFormData.price
    };
    const newProducts = [...products, newProduct];
    setProducts(newProducts);
    setformModalType(null);
    setAddFormData({
      desc: "",
      price: ""
    });
  };

  const handleFormCancel = () => {
    setformModalType(null);
    setAddFormData({
      desc: "",
      price: ""
    })
  }
  return (
    <Box sx={{

      width: '100%',

      height: '80vh',

      backgroundColor: 'background.paper',

      color: 'black',

      textAlign: 'left'

    }}>
      {/* <AppMenu /> */}

      <List>
        <Button variant="contained"
          onClick={() => setformModalType("add")}
          activeStyle={activeStyle}
        >
          新增
        </Button>
        {products.map((product, index) =>

          <ListItem button divider key={index} onClick={(event) => handleListItemClick(event, index)} selected={selectedIndex === index}>

            <ListItemText primary={product.desc} secondary={"NT$" + product.price}></ListItemText>

          </ListItem>)}

      </List>
      {formModalType === null ? (<DataModal isOpen={false} modalType={""} formData={""} handleFormChange={""} handleFormSubmit={""} handleFormCancel={""} />) : (<DataModal isOpen={true} modalType={"新增"} formData={addFormData} handleFormChange={handleAddFormChange} handleFormSubmit={handleAddFormSubmit} handleFormCancel={handleFormCancel} />)
      }
    </Box>

  )
}

import React, { useState } from 'react';
import './App.css';
import ProductList from './component/ProductList';
import Article from './component/Article';
import FullWidthTabs from './component/Tab'
import AppMenu from './component/AppMenu'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={FullWidthTabs} />
          <Route path="/product" component={ProductList} />
          <Route path="/article" component={Article} />
          {/* <Route path="/appbar" component={AppMenu} /> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;

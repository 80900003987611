import React from "react";
import MDEditor from '@uiw/react-md-editor';
import AppMenu from "./AppMenu";

export default function App() {
    const [value, setValue] = React.useState("**Hello world!!!**");
    return (
        <div style={{ width: '100%' }}>
            {/* <AppMenu /> */}
            <MDEditor
                value={value}
                onChange={setValue}
            />
            <MDEditor.Markdown source={value} />
        </div>
    );
}